/** @format */

import React, { lazy, useEffect } from "react";
import { Switch, Route, useHistory } from "react-router-dom";
const Main = lazy(() => import('./Main'));

const Router = () => {
  const { listen } = useHistory();
  useEffect(() => {
    const unlisten = listen((location) => {
      if (!window.gtag) return;
      window.gtag("config", "GTM-PBPWNPS", {
        page_path: location.pathname,
      });
    });
    return unlisten;
  }, [listen]);

  return (
    <Switch>
      <Route path="/">
        <Main />
      </Route>
    </Switch>
  );
};

export default Router;
